import React from "react"
import "./call.module.scss"
import headerStyles from "./call.module.scss"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Call = () => {
	return (
		<div className={ `${headerStyles.callBoxContainer}`} >
			<div id="callBox" className={ `${headerStyles.callBox}`}>
				<span id="callBoxClose" className={ `${headerStyles.callBoxClose}`}>&times;</span>
				<div className={ `${headerStyles.callBoxLine}`}></div>
				<p className={ `${headerStyles.callBoxTitle}`} >Юлия</p>
				<p className={ `${headerStyles.callBoxDescription}`} >Здравствуйте! 👋 Готова помочь Вам. Напишите мне, если у Вас появятся вопросы 😉</p>
			</div>

			{/*<img id="callButton" src="/img/user5.gif" alt="Юлия Бабанова" className={ `${headerStyles.callButton}`}></img>*/}

			{/*<video id="callButton" className={ `${headerStyles.callButton}`} src="/img/user5.mp4" loop muted playsinline uk-video="autoplay: inview"></video>*/}

			<video id="callButton" className={ `${headerStyles.callButton}`} preload autoplay muted loop playsinline poster="/img/user7.png" uk-video="autoplay: inview" >
    			<source src="/img/user5.mp4" type="video/mp4" />
    			<source src="/img/user5.ogg" type="video/ogg" />
			</video>
			
			<OutboundLink id="yandexEvent" href="tel:88005519518">
				{/*<img id="callButton" src="/img/user5.gif" alt="Юлия Бабанова" className={ `${headerStyles.callButton2}`}></img>*/}

				<video id="callButton" className={ `${headerStyles.callButton2}`} preload autoplay muted loop playsinline poster="/img/user7.png" uk-video="autoplay: inview" >
    				<source src="/img/user5.mp4" type="video/mp4" />
    				<source src="/img/user5.ogg" type="video/ogg" />
				</video>
            </OutboundLink>
		</div> 
	)
}

export default Call


