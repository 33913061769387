import React from "react"
import { Helmet } from 'react-helmet'

const Seo = (props) => {
	return (
		<Helmet defer={false}>
		
			<meta name="format-detection" content="telephone=no" />
			<title>{props.title}</title>
        	<meta name="title" content={props.title} />
			<meta name="description" content={props.description} />

            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://юридические-услуги-казань.рф" />
            <meta property="og:title" content={props.title} />
            <meta property="og:description" content={props.description} />
            <meta property="og:image" content="/img/og.png" />

            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://юридические-услуги-казань.рф"></meta>
            <meta property="twitter:title" content={props.title} />
            <meta property="twitter:description" content={props.description} />
            <meta property="twitter:image" content="/img/twi.png" />

			<link rel="apple-touch-icon" sizes="180x180" href="/img/apple-touch-icon.png"/>
			<link rel="icon" type="image/png" sizes="32x32" href="/img/favicon-32x32.png"/>
			<link rel="icon" type="image/png" sizes="16x16" href="/img/favicon-16x16.png"/>
			<link rel="manifest" href="/img/site.webmanifest"/>
			<link rel="mask-icon" href="/img/safari-pinned-tab.svg" color="#5bbad5"/>
			<meta name="msapplication-TileColor" content="#ffffff"/>
			<meta name="msapplication-TileImage" content="/img/mstile-144x144.png"/>
			<meta name="theme-color" content="#ffffff"></meta>

			<meta name="yandex-verification" content="029b1dfb3af21cc7" />
			<meta name="google-site-verification" content="YHSwTsSb5HntUVUUgw_HXqhmGKzy4npvo8XbEFl5a2Q" />

		</Helmet>
	)
}

export default Seo
