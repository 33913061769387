import React from "react"
import "./sale.module.scss"
import headerStyles from "./sale.module.scss"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const Sale = () => {
	
	return (
        <div id="salemodal" className={ `${headerStyles.salemodal}`}>
            <div className={ `${headerStyles.saleContent}`} >
                <div className={ `${headerStyles.saleImgBackground}`}></div>
                <div className={ `${headerStyles.modalContentClose}`} ><span id="modalSaleClose" className={ `${headerStyles.close}`} title="Close Modal">&times;</span></div>
                <div className={ `${headerStyles.saleContainer}`} >
                    <div className={ `${headerStyles.saleLeft}`} >
                        <div className={ `${headerStyles.saleText}`}>

                        <p><span className={ `${headerStyles.sliderYellow}`} ></span></p>
                        
                        </div>
                    </div>
                    <div className={ `${headerStyles.saleRight}`} >
                        <p className={ `${headerStyles.saleTitle}`} >Консультация от опытных юристов в день обращения. Широкий спектр услуг. Бесплатно!</p>
                        <p className={ `${headerStyles.saleTimerTitle}`} >До окончания акции осталось:</p>
                        <div className={ `${headerStyles.saleTimer}`} >
                            <div className={ `${headerStyles.saleTimerBox}`} >
                                <div className={ `${headerStyles.saleTimerFon}`} >
                                    <p id="saleH" className={ `${headerStyles.saleH}`} >00</p>
                                </div>
                                <p className={ `${headerStyles.saleTimerText}`}>часы</p>
                            </div>
                            <div className={ `${headerStyles.saleRazdel}`} ><span>:</span></div>
                            <div className={ `${headerStyles.saleTimerBox}`} >
                                <div className={ `${headerStyles.saleTimerFon}`} >
                                    <p id="saleM" className={ `${headerStyles.saleM}`}>00</p>
                                </div>
                                <p className={ `${headerStyles.saleTimerText}`}>минуты</p>
                            </div>
                            <div className={ `${headerStyles.saleRazdel}`} ><span>:</span></div>
                            <div className={ `${headerStyles.saleTimerBox}`} >
                                <div className={ `${headerStyles.saleTimerFon}`} >
                                    <p id="saleS" className={ `${headerStyles.saleS}`} >00</p>
                                </div>
                                <p className={ `${headerStyles.saleTimerText}`}>секунды</p>
                            </div>
                        </div>
                        <form className={ `${headerStyles.callForm}`} id="call-form">
                            <div className={ `${headerStyles.formBox}`} >
                                <input className={ `${headerStyles.contactFormName}`}  type="text" placeholder="Введите имя" required="" />
                                <input className={ `${headerStyles.contactFormCall}`}  type="text" placeholder="Номер телефона" required="" />
                            </div>
                            <div className={ `${headerStyles.formBox}`} >
                                <p className={ `${headerStyles.contactFormPolicy}`}>Нажимая на кнопку "Записаться", я даю согласие на <a href="/obrabotka" target="_blank">обработку персональных данных</a></p>
                            </div>
                            <div >
                                <button id="yandexEvent" className={ `${headerStyles.contactFormButton}` }
                                onClick={e => {
                                    trackCustomEvent({
                                    category: "form",
                                    action: "click",
                                    label: "Gatsby Plugin Example Campaign"
                                    })
                                }}
                                ><span>Записаться</span>
                                </button>
                            </div>
                            <div >
                                <a id="modalSaleCloseBtn" className={ `${headerStyles.saleClose}`}>
                                    <span>Меня это не интересует!</span>
                                </a>
                            </div>
                        </form>
                        <div className={ `${headerStyles.timenow}`} ></div>
                    </div>
                </div>
            </div>
        </div>
	)
}

export default Sale


