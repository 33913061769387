import React from "react"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import "./contactForm.module.scss"
import headerStyles from "./contactForm.module.scss"

const contactForm = () => {
	return (
        <div id="contactForm" uk-modal="true;" >
            <div className={ `uk-modal-dialog uk-modal-body ${headerStyles.contactFormContainer}`}>
                
                <button className="uk-modal-close-default" type="button" uk-close="true"></button>

                <div className={`uk-text-center ${headerStyles.MapFormTop15}`}>
                    <img src="/img/call.png" width="220" height="220"></img>
                </div>

                <h3 className={`uk-text-center ${headerStyles.contactFormTitle}`}>Оставьте свой номер телефона</h3>
                <p className={`uk-margin-remove-top uk-text-center ${headerStyles.contactFormDescription}`}>Мы перезвоним Вам в течение 1 минуты!</p>

                <form id="mapForm" className={ `${headerStyles.MapForm}` }>
                    <fieldset className={ `uk-fieldset` } >

                        <div className={ `uk-margin uk-flex uk-flex-row uk-flex-center` }>
                            <div className={ `uk-inline` }>                                           
                                <img className={ `${headerStyles.MapFormInputSvg}` } src="/svg/rus.svg" width="21" height="14" uk-svg-rus="true" alt="Алмаз Казань" ></img>

                                <input className={ ` ${headerStyles.MapFormInput}` } type="text" placeholder="Ваш номер телефона"></input>
                            </div>
                        </div>

                        <div className={ `uk-margin uk-flex uk-flex-row uk-flex-center uk-flex-middle` }>
                            <input className={ `uk-checkbox` } type="checkbox"></input>

                            <p className={ `${headerStyles.MapFormPolicy} uk-margin-small-left` } >
                                Нажимая на кнопку "ЗАКАЗАТЬ ЗВОНОК", вы даете согласие на <a href="/obrabotka" target="_blank" className={ `${headerStyles.MapFormPolicyLink}` }> обработку своих персональных данных </a> и соглашаетесь с <a href="/policy" target="_blank" className={ `${headerStyles.MapFormPolicyLink}` }>пользовательским соглашением</a>
                            </p>
                        </div>

                        <div className={ `uk-margin uk-flex uk-flex-row uk-flex-center` }>
                            <button id="yandexEvent" className={ `uk-button uk-button-default ${headerStyles.MapFormButton}` }
                                onClick={e => {
                                    trackCustomEvent({
                                    category: "form",
                                    action: "click",
                                    label: "Gatsby Plugin Example Campaign"
                                    })
                                }}
                                >ЗАКАЗАТЬ ЗВОНОК
                            </button>

                        </div>

                    </fieldset>
                </form>

            </div>
        </div>
	)
}

export default contactForm


