import React from "react"
import "./stado.module.scss"
import headerStyles from "./stado.module.scss"

const Stado = () => {

	return (
        <div id="stado">
	    </div> 
	)
}

export default Stado
