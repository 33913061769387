import React from "react"
import "./call.module.scss"
import headerStyles from "./callForm.module.scss"

const callForm = () => {
	return (
        <div className={ `${headerStyles.modalCall}`} id="callPage">
            <form className={ `${headerStyles.modalContent} ${headerStyles.animate}`} >
                <div className={ `${headerStyles.imgcontainer}`}><span id="close" className={ `${headerStyles.close}`} title="Close Modal">&times;</span>
                    <div className={ `${headerStyles.callHeader}`}>
                        <div>
                            <h2 className={ `${headerStyles.imgcontainerH2}`}>Здравствуйте!</h2>
                        </div>
                        <div>
                            <p className={ `${headerStyles.imgcontainerP}`}>Выберите удобный для вас способ связи и задайте ваш вопрос</p>
                        </div>
                    </div>
                </div>
                <div className={ `${headerStyles.container}`}>
                    <div className={ `${headerStyles.callSocial}`} >
                        <div className={ `${headerStyles.callInfo}`} >
                            <div className={ `${headerStyles.callAva}`} ><img src="/img/user4.png" alt="Юлия Бабанова"></img></div>

                            <div className={ `${headerStyles.callInfoContent}`} >
                                <h4>Юлия </h4>
                                <p>Специалист по недвижимости</p>
                            </div>
                        </div>
                    
                        <div className={ `${headerStyles.callSocialButtons}`} >

                            <div className={ `${headerStyles.callButtonList}`} >
                                <a className={ `${headerStyles.callButtonTelegram}`} href="tg://resolve?domain=79656075744" ><p>Telegram</p>
                                </a>

                                <a className={ `${headerStyles.callButtonWhatsapp}`} href="https://wa.me/79656075744" ><p>WhatsApp</p>
                                </a>
                            </div>

                            <div className={ `${headerStyles.callButtonList2}`} >
                                <div className={ `${headerStyles.callButtonList}`} >
                                    <a className={ `${headerStyles.callButtonViber}`} href="viber://add?number=79656075744" ></a>
                                </div>
                                <div className={ `${headerStyles.callButtonList}`} >
                                    <a className={ `${headerStyles.callButtonInstagramCall}`} href="https://www.instagram.com/knktatar" ></a>
                                </div>
                                <div className={ `${headerStyles.callButtonList}`} >
                                    <a className={ `${headerStyles.callButtonVkCall}`} href="https://vk.com/knktatar" ></a>
                                </div>
                        </div>
                    </div>
                </div>
                <div className={ `${headerStyles.callButtons}`}>
                    <div className={ `${headerStyles.callSocialButtons}`} >
                        <div className={ `${headerStyles.callButtonList}`} ><button id="callButtonZvonok" className={ `${headerStyles.callButtonZvonok}`}><p>Заказать звонок</p></button></div>
                    </div>
                </div>
                </div>
            </form>
        </div>
	)
}

export default callForm


