import React from "react"
import "./footer.module.scss"
import headerStyles from "./footer.module.scss"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Footer = () => {
	return (
		<footer className={ `uk-section ${headerStyles.footerBackground}` }>
            <div className={ `uk-container` }>
                <div className={ `uk-grid-match uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-3@l uk-flex-center` } uk-grid="true">

                    <div>
                        <div className={ `uk-flex uk-flex-column uk-flex-left` } >
							<img src="/svg/logofooter.svg" width="191" height="60" uk-svg-logofooter="true" alt="Алмаз Казань"></img>
							<p className={ `${headerStyles.footerText}` }>ИП Нигматуллин Эдуард Ильдарович</p>
							<p className={ `${headerStyles.footerText}` }>ОГРНИП 319169000192479</p>

							<div className={ `${headerStyles.footerImages}` }>
								<a><img  src="/img/apple.png" alt="Мобильное приложение Алмаз Казань ios"></img></a>
								<a><img className={ `uk-margin-small-left` } src="/img/google.png" alt="Мобильное приложение Алмаз Казань android"></img></a>
							</div>

							<a href="/policy" target="_blank" className={ `${headerStyles.footerLink}` }>Пользовательское соглашение</a>
							<a href="/sitemap.xml" target="_blank" className={ `${headerStyles.footerLink}` }>Карта сайта</a>

							<div className={ `uk-flex uk-flex-row ${headerStyles.footerOOCC}` }> 
								<img className={ `uk-flex uk-flex-row ${headerStyles.footerOOCCLogo}` } src="/svg/oocc.svg" width="45" height="45" uk-svg-oocc="true" alt="Данила Бабанов"></img>
								<div className={ `uk-flex uk-flex-column` }> 
									<a href="https://oocc.ru" className={ `uk-text-middle uk-margin-small-left ${headerStyles.footerSvgText}` } >Создание и продвижение сайтов. Разработка мобильных приложений.</a><a href="https://danilababanov.ru" className={ `uk-text-middle uk-margin-small-left ${headerStyles.footerSvgText}` } >Данила Бабанов</a>
								</div>
							</div>
                        </div>
                    </div>

					<div>
                        <div className={ `uk-flex uk-flex-column uk-flex-left` } >
							<p className={ `${headerStyles.footerH}` }>Наши проекты</p>

							<a className={ `uk-flex uk-flex-row ${headerStyles.footerYf1}` } href="https://агентство-недвижимости-алмаз.рф">
								<img className={ `${headerStyles.footerRight}` } src="/svg/f18.svg" width="15" height="15" uk-svg-f18="true" alt="Агентство недвижимости Алмаз"></img>
								Агентство недвижимости Алмаз
							</a>

							<a className={ `uk-flex uk-flex-row ${headerStyles.footerYf}` } href="https://вторичное-жилье-казань.рф">
								<img className={ `${headerStyles.footerRight}` } src="/svg/f9.svg" width="15" height="15" uk-svg-f9="true" alt="Вторичное жилье Казани"></img>
								Вторичное жилье Казани
							</a>

							<a className={ `uk-flex uk-flex-row ${headerStyles.footerYf}` } href="https://квартиры-новостройки-казань.рф">
								<img className={ `${headerStyles.footerRight}` } src="/svg/f10.svg" width="15" height="15" uk-svg-f10="true" alt="Новостройки Казани"></img>
								Новостройки Казани
							</a>

							<a className={ `uk-flex uk-flex-row ${headerStyles.footerYf}` } href="https://квартиры-аренда-казань.рф">
								<img className={ `${headerStyles.footerRight}` } src="/svg/f11.svg" width="15" height="15" uk-svg-f11="true" alt="Аренда квартир в Казани"></img>
								Аренда квартир в Казани
							</a>

							<a className={ `uk-flex uk-flex-row ${headerStyles.footerYf}` } href="https://элитное-жилье-казань.рф">
								<img className={ `${headerStyles.footerRight}` } src="/svg/f12.svg" width="15" height="15" uk-svg-f12="true" alt="Элитное жилье Казани"></img>
								Элитное жилье Казани
							</a>

							<a className={ `uk-flex uk-flex-row ${headerStyles.footerYf}` } href="https://недвижимость-за-рубежом-купить.рф">
								<img className={ `${headerStyles.footerRight}` } src="/svg/f13.svg" width="15" height="15" uk-svg-f13="true" alt="Недвижимость за рубежом"></img>
								Недвижимость за рубежом
							</a>

							<a className={ `uk-flex uk-flex-row ${headerStyles.footerYf}` } href="https://дома-дачи-коттеджи-казань.рф">
								<img className={ `${headerStyles.footerRight}` } src="/svg/f14.svg" width="15" height="15" uk-svg-f14="true" alt="Дома, дачи, коттеджи Казани"></img>
								Дома, дачи, коттеджи Казани
							</a>

							<a className={ `uk-flex uk-flex-row ${headerStyles.footerYf}` } href="https://земельные-участки-казань.рф">
								<img className={ `${headerStyles.footerRight}` } src="/svg/f15.svg" width="15" height="15" uk-svg-f15="true" alt="Земельные участки Казани"></img>
								Земельные участки Казани
							</a>

							<a className={ `uk-flex uk-flex-row ${headerStyles.footerYf}` } href="https://коттеджные-поселки-казань.рф">
								<img className={ `${headerStyles.footerRight}` } src="/svg/f16.svg" width="15" height="15" uk-svg-f16="true" alt="Коттеджные поселки Казани"></img>
								Коттеджные поселки Казани
							</a>

							<a className={ `uk-flex uk-flex-row ${headerStyles.footerYf}` } href="https://коммерческая-недвижимость-казань.рф">
								<img className={ `${headerStyles.footerRight}` } src="/svg/f17.svg" width="15" height="15" uk-svg-f17="true" alt="Коммерческая недвижимость"></img>
								Коммерческая недвижимость
							</a>

							<a className={ `uk-flex uk-flex-row uk-flex-middle ${headerStyles.footerYf}` } href="https://knk.tatar">
								<img className={ `${headerStyles.footerRight}` } src="/svg/f18.svg" width="15" height="15" uk-svg-f18="true" alt="knk.tatar"></img>
								knk.tatar
							</a>

							<a className={ `uk-flex uk-flex-row uk-flex-middle ${headerStyles.footerYf}` } href="https://almaz.pw">
								<img className={ `${headerStyles.footerRight}` } src="/svg/f18.svg" width="15" height="15" uk-svg-f18="true" alt="Алмаз Казань"></img>
								Алмаз Казань
							</a>

							<a className={ `uk-flex uk-flex-row uk-flex-middle ${headerStyles.footerYf}` } href="https://almazcrm.ru">
								<img className={ `${headerStyles.footerRight}` } src="/svg/f18.svg" width="15" height="15" uk-svg-f18="true" alt="Алмаз CRM"></img>
								Алмаз CRM
							</a>

							
                        </div>
                    </div>

					<div>
                        <div className={ `uk-flex uk-flex-column uk-flex-left` } >
							<p className={ `${headerStyles.footerH}` }>Контакты</p>

							<p className={ `${headerStyles.footerContact1}` }>Бесплатный звонок по РФ</p>
							<OutboundLink id="yandexEvent" className={ `${headerStyles.footerContact2}` } href="tel:88005519518">
								8 (800) 551-95-18
                            </OutboundLink>
							<div className={ `uk-flex uk-flex-row ${headerStyles.footerContact3}` }>
								<OutboundLink id="yandexEvent" href="https://wa.me/79656075744">
									<img className={ `uk-preserve uk-margin-small-right` } src="/svg/whatsapp.svg" width="24" height="24" uk-svg-whatsapp="true" alt="whatsapp Алмаз Казань"></img>
                            	</OutboundLink>

								<OutboundLink id="yandexEvent" href="viber://add?number=79656075744">
									<img className={ `uk-preserve uk-margin-small-right` } src="/svg/viber.svg" width="24" height="24" uk-svg-viber="true" alt="viber Алмаз Казань"></img>
                            	</OutboundLink>
								
								<OutboundLink id="yandexEvent" href="tg://resolve?domain=79656075744">
									<img className={ `uk-preserve uk-margin-small-right` } src="/svg/telegram.svg" width="24" height="24" uk-svg-telegram="true" alt="telegram Алмаз Казань"></img>
                            	</OutboundLink>

								<OutboundLink id="yandexEvent" className={ `${headerStyles.footerContact31}` } href="tel:+79656075744">
									+7 (965) 607-57-44
                            	</OutboundLink>
							</div>
							<p className={ `${headerStyles.footerContact4}` }>
								<img className={ `uk-margin-small-right` } src="/svg/yf17.svg" width="27" height="25" uk-svg-yf17="true" alt="г.Казань, ул.Щапова, д.26"></img>
								г.Казань, ул.Щапова, д.26
							</p>
							<a href="mailto:info@knk.tatar" className={ `${headerStyles.footerContact5}` }>
								<img className={ `uk-margin-small-right` } src="/svg/yf18.svg" width="27" height="25" uk-svg-yf18="true" alt="info@knk.tatar"></img>
								info@knk.tatar
							</a>
							<p className={ `${headerStyles.footerContact6}` }>
								<img className={ `uk-margin-small-right` } src="/svg/yf19.svg" width="27" height="25" uk-svg-yf19="true" alt="Время работы Алмаз Казань: 9:00 – 21:00"></img>
								Время работы: 9:00 – 21:00
							</p>

                        </div>
                    </div>
                
                </div>

				<hr></hr>

				<div className={ `uk-grid-match uk-child-width-1-1@s uk-child-width-1-1@m uk-child-width-1-1@l uk-child-width-1-1@xl uk-flex-center` } uk-grid="true">
					<p className={ `${headerStyles.footerText2}` } >
					Настоящим информируем вас о том, что вся информация, размещенная на данном сайте, ни при каких обстоятельствах не может признаваться публичной офертой в соответствии со ст. 437.2 Гражданского кодекса РФ. Копирование и воспроизведение материалов этого сайта возможно с согласия администрации сайта. Посещая сайт https://юридические-услуги-казань.рф, вы даете согласие на обработку данных cookies и иных пользовательских данных, сбор которых автоматически осуществляется администрацией сайта на условиях <a href="/obrabotka" target="_blank">«Положения об обработке персональных данных»</a>
					</p>
				</div>

            </div>

        </footer>
	)
}

export default Footer
