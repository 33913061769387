import React from "react"
import { withPrefix } from "gatsby"
import { Helmet } from 'react-helmet'

const Meta = () => {
	return (
		<Helmet defer={false}>
            <meta charset="utf-8" />
            <link media="none" onload="media='all'" rel="stylesheet" href="https://cdn.jsdelivr.net/npm/uikit@3.5.4/dist/css/uikit.min.css" />
            <script async="async" src="https://cdn.jsdelivr.net/npm/uikit@3.5.4/dist/js/uikit.min.js"></script>
            <script async="async" src="https://cdn.jsdelivr.net/npm/uikit@3.5.4/dist/js/uikit-icons.min.js"></script>

            <script async="async" src="https://api-maps.yandex.ru/2.1/?apikey=64695243-1c52-4833-a43c-55a16dd36502&lang=ru_RU" ></script>

            <link rel="stylesheet" type="text/css" href={withPrefix('style/style.css')}></link>
            
            <link href="https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet"></link>
            
            <link rel="stylesheet" type="text/css" href="https://csshake.surge.sh/csshake.min.css"></link>

            <script async="async" src={withPrefix('js/dragscroll.js')} type="text/javascript" />

            <script async src="https://www.googletagmanager.com/gtag/js?id=G-H0LFQVLC1D"></script>

        </Helmet>
	)
}

export default Meta
