import React from "react"
import Meta from "../components/meta"
import Footer from "../components/footer"

import "./layout.module.scss"
import layoutStyles from "./layout.module.scss"
import "../styles/index.scss"

const Layout = (props) => {
	return (
		<div id="goodContainer" className={ `${layoutStyles.goodContainer}` }>
			<Meta />
			 
			<main className={ `${layoutStyles.main}` }>
				{props.children}
			</main>   

			<Footer />
		</div>
	)
}

export default Layout
